
<template>
    <div data-app style="margin-top: -8px">
        
        <div v-bind:class="currentVideo == 'platform' || currentVideo == 'abacus'? 'backdrop':'nobackdrop'"></div>
        <div>
            <UserInfo :begin="begin" @Videoshow="onClickChild" :currentVideo="currentVideo"></UserInfo>
        </div>
        <!-- <div class="video-arrow" v-if="(currentVideo == 'platform' && !learndialogue) || (currentVideo == 'abacus' && !learndialogue)">
            <p>Para comenzar da clic</p>
            <img :src="require('@/assets/images/videoarrow.svg')" v-bind:style="currentVideo == 'platform'?'margin-right:600px;':'margin-right:435px;'">
        </div> -->
        <div>
            <div class="row mt-5">
                <div class="here">
                    <img :src="require('@/assets/images/abacas.svg')" style="width:50px">
                    <div class="here-p ml-4"><p>Aquí usas ábaco</p></div>
                </div>
                <div class="mt-5 mb-5 padding-30 abacus-practice-part">
                    <v-col class="text-center d-flex justify-content-center abacus-practice-item" lg="2" md="3" sm="6" 
                    v-for="(item) in homeData.abacus" v-bind:key="item._id" 
                    @click="LearnAbacus(item)">
                        <div>
                            <img v-if="item.status!='lock'" :src="require('@/assets/images/openabacus.svg')">
                            <img v-if="item.status=='lock'" :src="require('@/assets/images/closeabacus.svg')">
                            <div class="p-2" v-if="item.status != 'lock'" v-bind:class="item.status=='passed'?'closeabacus':'openabacus'">
                                <p class="description">{{item.title}}</p>
                                <p class="timetostart"  v-if="item.status=='opened'">¡Hora de comenzar!</p>
                                <p class="checkcircle"  v-if="item.status=='passed'"><span class="material-icons">check_circle</span></p>
                            </div>
                            <div class="closeabacus p-2" v-if="item.status=='lock'">
                                <p class="closedescription mb-0">{{item.title}}</p>
                            </div>
                        </div>                    
                    </v-col>
                    <v-col class="text-center d-flex justify-content-center abacus-practice-item" lg="2" md="3" sm="6" 
                    v-for="(item) in homeData.abacusvideo" v-bind:key="item._id" 
                    @click="LearnAbacus(item)">
                        <div>
                            <img v-if="item.status!='lock'" :src="require('@/assets/images/abacusvideo.png')" width="150">
                            <img v-if="item.status=='lock'" :src="require('@/assets/images/abacusvideolock.png')" width="150">
                            <div class="p-2" v-if="item.status != 'lock'" v-bind:class="item.status=='passed'?'closeabacus':'openabacus'">
                                <p class="description">{{item.title}}</p>
                                <p class="timetostart"  v-if="item.status=='opened'">¡Hora de comenzar!</p>
                                <p class="checkcircle"  v-if="item.status=='passed'"><span class="material-icons">check_circle</span></p>
                            </div>
                            <div class="closeabacus p-2" v-if="item.status=='lock'">
                                <p class="closedescription mb-0">{{item.title}}</p>
                            </div>
                        </div>                    
                    </v-col>
                </div>
            </div>
            <v-divider></v-divider>
           <div class="row mt-10">
                <div class="here">
                    <img :src="require('@/assets/images/brain.svg')" style="width:50px">
                    <div class="here-p ml-4"><p>Aquí usas tu ábaco mental</p></div>
                </div>
                <v-row class="w-100 mt-5 mb-5 padding-30">
                    <v-col class="text-center d-flex justify-content-center abacus-practice-item" lg="2" md="3" sm="6" 
                    v-for="(item) in homeData.mental" v-bind:key="item._id" 
                    @click="LearnAbacus(item)">
                        <div>
                            <img v-if="item.status!='lock'" :src="require('@/assets/images/mental.png')">
                            <img v-if="item.status=='lock'" :src="require('@/assets/images/mentallock.png')">
                            <div class="p-2" v-if="item.status != 'lock'" v-bind:class="item.status=='passed'?'closeabacus':'openabacus'">
                                <p class="description">{{item.title}}</p>
                                <p class="timetostart"  v-if="item.status=='opened'">¡Hora de comenzar!</p>
                                <p class="checkcircle"  v-if="item.status=='passed'"><span class="material-icons">check_circle</span></p>
                            </div>
                            <div class="closeabacus p-2" v-if="item.status=='lock'">
                                <p class="closedescription mb-0">{{item.title}}</p>
                            </div>
                        </div>                    
                    </v-col>
                    <v-col class="text-center d-flex justify-content-center abacus-practice-item" lg="2" md="3" sm="6" 
                    v-for="(item) in homeData.mentalvideo" v-bind:key="item._id" 
                    @click="LearnAbacus(item)">
                        <div>
                            <img v-if="item.status!='lock'" :src="require('@/assets/images/mentalvideo.png')" width="150">
                            <img v-if="item.status=='lock'" :src="require('@/assets/images/mentalvideolock.png')" width="150">
                            <div class="p-2" v-if="item.status != 'lock'" v-bind:class="item.status=='passed'?'closeabacus':'openabacus'">
                                <p class="description">{{item.title}}</p>
                                <p class="timetostart"  v-if="item.status=='opened'">¡Hora de comenzar!</p>
                                <p class="checkcircle"  v-if="item.status=='passed'"><span class="material-icons">check_circle</span></p>
                            </div>
                            <div class="closeabacus p-2" v-if="item.status=='lock'">
                                <p class="closedescription mb-0">{{item.title}}</p>
                            </div>
                        </div>                    
                    </v-col>
                </v-row>
            </div>
            <v-divider></v-divider>
            <v-row class="mt-10">
                <div class="here">
                    <img :src="require('@/assets/images/abacas.svg')" style="width:50px;margin-top:5px">
                    <!-- <img :src="require('@/assets/images/brain.svg')" style="width:50px"> -->
                    <div class="here-p ml-4"><p>Aquí usas todas las herramientas</p></div>
                </div>
            </v-row>
            <v-row class="w-100 mt-5 mb-5 padding-30">
                    <v-col class="text-center d-flex justify-content-center abacus-practice-item" lg="2" md="3" sm="6" 
                    v-for="(item) in homeData.battle" v-bind:key="item._id" 
                    @click="LearnAbacus(item)">
                        <div>
                            <img v-if="item.status!='lock'" :src="require('@/assets/images/battle.png')" width="150">
                            <img v-if="item.status=='lock'" :src="require('@/assets/images/battlelock.png')" width="150">
                            <div class="p-2" v-if="item.status != 'lock'" v-bind:class="item.status=='passed'?'closeabacus':'openabacus'">
                                <p class="description">{{item.title}}</p>
                                <p class="timetostart"  v-if="item.status=='opened'">¡Hora de comenzar!</p>
                                <p class="checkcircle"  v-if="item.status=='passed'"><span class="material-icons">check_circle</span></p>
                            </div>
                            <div class="closeabacus p-2" v-if="item.status=='lock'">
                                <p class="closedescription mb-0">{{item.title}}</p>
                            </div>
                        </div>                    
                    </v-col>
                    <v-col class="text-center d-flex justify-content-center abacus-practice-item" lg="2" md="3" sm="6" 
                    v-for="(item) in homeData.final" v-bind:key="item._id" 
                    @click="LearnAbacus(item)">
                        <div>
                            <img v-if="item.status!='lock'" :src="require('@/assets/images/final.png')" width="150">
                            <img v-if="item.status=='lock'" :src="require('@/assets/images/finallock.png')" width="150">
                            <div class="p-2" v-if="item.status != 'lock'" v-bind:class="item.status=='passed'?'closeabacus':'openabacus'">
                                <p class="description">{{item.title}}</p>
                                <p class="timetostart"  v-if="item.status=='opened'">¡Hora de comenzar!</p>
                                <p class="checkcircle"  v-if="item.status=='passed'"><span class="material-icons">check_circle</span></p>
                            </div>
                            <div class="closeabacus p-2" v-if="item.status=='lock'">
                                <p class="closedescription mb-0">{{item.title}}</p>
                            </div>
                        </div>                    
                    </v-col>
                </v-row>
        </div>      
         <v-dialog v-model="learndialogue" max-width="1000" max-height="600" class="videoscreen" persistent>
            <vimeo-player ref="player" 
            class="vimeo-player"
            :video-id="videoID" 
            @ended="onInitVideoEnded"
            :player-width="1000"
            :player-height="600" 
            :options="options">
            </vimeo-player>
         </v-dialog>
    </div>
</template>

<style lang="scss">

@import "@/assets/sass/pages/begin/begin.scss";

.Zindex{
    z-index: 200;
}
.noZindex{
    z-index: 9;
}
</style>

<script>
import UserInfo from "@/view/content/user/Userinfo.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import {
  GET_HOME_DATA,
  SET_HOME_DATA
} from "@/core/services/store/data.module.js";

import {
  POST_PROGRESS_DATA,
} from "@/core/services/store/progress.module.js";

import { VERIFY_AUTH } from "@/core/services/store/auth.module";

import { mapGetters } from "vuex";

export default {
  name: "inicio",
  components: {
    UserInfo,
  },
  data() {
      return {
          previous:"",
          VideoUrl:"",
          learndialogue: false,
          abacusTechVideo:false,
          CourseTitle:"",
          isbackspace:"",
          begin:true,
          currentVideo:"",
          videoID: '',
            width: 800,
            options: {
                muted: false,
                autoplay: true,
                controls: true,
            },
            playerReady: false
      }
  },
  created() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.$store
          .dispatch(GET_HOME_DATA, { parent: ""})
          .then(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

                if(this.homeData.video[1].status == 'opened') {
                    this.currentVideo = 'abacus'
                }

                if(this.homeData.video[0].status == 'opened') {
                    this.currentVideo = 'platform'
                }

                if(this.currentVideo != '') {
                    this.$store.dispatch(ADD_BODY_CLASSNAME, "no-scroll");
                }

                console.log(this.homeData);
          })
          .catch(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
  },
  methods: {
    onClickChild(val, videoId) {
        this.videoID = videoId;
        this.currentVideo = val;
        this.learndialogue = true;
        this.$store.dispatch(ADD_BODY_CLASSNAME, "no-scroll");
    },

    onInitVideoEnded() {
        this.learndialogue = false;
        console.log(this.currentVideo);
        if(this.currentVideo == 'platform'){
            if(this.homeData.video[0].status == 'opened') {
                this.homeData.video[0].status = 'passed';
                this.progress({
                    lesson_id: this.homeData.video[0]._id,
                    parent: this.homeData.video[0].parent,
                    type: 'video',
                    status: 'passed',
                    step: this.homeData.video[0].step,
                });
                this.currentVideo = 'abacus';
            } else {
                this.currentVideo = '';
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "no-scroll");
            }
          } else if (this.currentVideo == 'abacus'){
            if(this.homeData.video[0].status == 'passed' && this.homeData.video[1].status == 'opened') {
                this.homeData.video[1].status = 'passed';
                this.homeData.abacus[0].status = 'opened';
                this.getUser();
                this.progress({
                    lesson_id: this.homeData.video[1]._id,
                    parent: this.homeData.video[1].parent,
                    type: 'video',
                    status: 'passed',
                    step: this.homeData.video[1].step,
                });
                this.currentVideo = 'abacus';
            }
            this.currentVideo = '';
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "no-scroll");
          } else if (this.currentVideo == 'explain') {
            this.currentVideo = '';
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "no-scroll");
          } else if (this.currentVideo == 'abacusvideo') {
            if (this.homeData.abacusvideo[0].status == 'opened') {
                this.homeData.abacusvideo[0].status = 'passed';
                this.homeData.mental[0].status = 'opened';
                this.getUser();
                this.progress({
                    lesson_id: this.homeData.abacusvideo[0]._id,
                    parent: this.homeData.abacusvideo[0].parent,
                    type: 'abacusvideo',
                    status: 'passed',
                    step: this.homeData.abacusvideo[0].step,
                });
            }
            this.currentVideo = '';
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "no-scroll");
          } else if (this.currentVideo == 'mentalvideo') {
            if (this.homeData.mentalvideo[0].status == 'opened') {
                this.homeData.mentalvideo[0].status = 'passed';
                this.homeData.battle[0].status = 'opened';
                this.getUser();
                this.progress({
                    lesson_id: this.homeData.mentalvideo[0]._id,
                    parent: this.homeData.mentalvideo[0].parent,
                    type: 'mentalvideo',
                    status: 'passed',
                    step: this.homeData.mentalvideo[0].step,
                });
            }
            this.currentVideo = '';
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "no-scroll");
          }
    },

    LearnAbacus(details) {      
        if(details.status == 'lock') {
            return;
        }

        if(details.type == 'abacusvideo' || details.type == 'mentalvideo') {
            this.videoID = details.video;
            this.currentVideo = details.type;
            this.learndialogue = true;
            this.$store.dispatch(ADD_BODY_CLASSNAME, "no-scroll");
            return;
        }

        this.$store.commit(SET_HOME_DATA, {});
        var pathname = new URL(location.href).pathname;
        this.previous = pathname;
        localStorage.setItem('previous',pathname)
        this.$router.push({name: 'beginItem', params: {
            id: details._id,
            parentTitle: details.title,
            level: `${details.step + 1}`
        }})
    },

    progress(params) {
        this.$store
          .dispatch(POST_PROGRESS_DATA, params)
          .then(() => {
                
          })
          .catch(() => {
                
          });
        },

    getUser() {
        this.$store
          .dispatch(VERIFY_AUTH)
          .then(() => {
                
          })
          .catch(() => {
                
          });
    }
  },
   computed: {
        ...mapGetters([
            "homeData"
        ]),
   }
}
</script>