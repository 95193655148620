<template>
    <div class="userinfo card card-custom card-stretch w-100">       
        <div class="go-teacher">
            <a class="teacher-help d-flex align-items-center" href="https://fastmind.mx" >
                <img class="mr-2" :src="require('@/assets/images/support-white.svg')">
                <p class="mb-0 mt-1">¿Necesitas ayuda de una maestra?</p>
            </a>
        </div>
        <div class="learning w-100 p-7">
            
            <div class="user-image">
                <div class="useravatar">
                    <router-link
                        to="/avatar">
                        <i class="menu-icon flaticon2-edit"></i>
                    </router-link>
                    <img v-for="item in currentUser.currentAvatar" class="avatar-image" :src="item.file" :key="item._id" :style="`z-index: ${item.zindex-900}`">
                </div>
                <div class="userbadge text-center">
                    <img :src="require('@/assets/images/badge_1.png')" v-if="currentUser.progress > 0">
                    <img :src="require('@/assets/images/badge_2.png')" v-if="currentUser.progress > 11">
                    <img :src="require('@/assets/images/badge_3.png')" v-if="currentUser.progress > 13">
                </div>
            </div>
            <div class="progress-video d-flex w-100 ml-15">
                <div class="username mt-3">
                    <p v-if="currentUser.first_name == ''">{{currentUser.email}}</p>
                    <p v-if="currentUser.first_name != ''">{{currentUser.first_name}}</p>
                    <div class="progress-grid">
                        <div class="progress-plugs">
                            <img :src="require(currentUser.progress >= 1? '@/assets/images/big_flug_active.svg':'@/assets/images/big_flug_deactive.svg')" class="big-img">
                            <img :src="require(currentUser.progress >= 2? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 3? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 4? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 5? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 6? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 7? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 8? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 9? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 10? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 12? '@/assets/images/small_cup.svg':
                            currentUser.progress >= 11? '@/assets/images/big_flug_active.svg':
                            '@/assets/images/big_flug_deactive.svg')" :class="currentUser.progress >= 12?'cup':'big-img'">
                            <img :src="require(currentUser.progress >= 12? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress >= 14? '@/assets/images/medium_cup.svg':
                            currentUser.progress >= 13? '@/assets/images/big_flug_active.svg':
                            '@/assets/images/big_flug_deactive.svg')" :class="currentUser.progress >= 14?'cup':'big-img'">
                            <img :src="require(currentUser.progress >= 14? '@/assets/images/small_flug_active.svg':'@/assets/images/small_flug_deactive.svg')">
                            <img :src="require(currentUser.progress == 15? '@/assets/images/big_cup.svg':
                            '@/assets/images/goal_flug.svg')" :class="currentUser.progress == 15?'cup':'big-img'">

                        </div>
                        <b-progress :max="100" height="12px" style="border-radius: 15px">
                            <b-progress-bar :value="currentUser.percent" :variant="'success'"></b-progress-bar>
                        </b-progress>
                        <div class="d-flex progress-label mt-2">
                            <p>Tu camino a la batalla final</p>
                        </div>
                        <div class="d-flex coin-label mt-2">
                            <img :src="require('@/assets/images/coin.svg')">
                            <p><span class="fastcoins">FastCoins</span> acumulados: <span class="coins">{{currentUser.points}}</span></p>
                        </div>
                    </div>
                </div>

                <div class="intro-videos mt-3">
                    <div v-if="begin" class="d-flex justify-content-around">
                        <img v-for="video in homeData.video" 
                        :key = "video._id" 
                        :src="require(`@/assets/images/${video.title}.svg`)" 
                        class="mx-6" width="150" height="150" 
                        @click="VideoClick(video.title, video.video)" 
                        v-bind:class="currentVideo==video.title?'videodisplay':'novideodisplay'">
                    </div> 
                    <div class="backspace" v-if="!begin">
                        <img :src="require('@/assets/images/back.svg')" @click="backspace()">
                    </div>               
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/user/userinfo.scss";
.videodisplay{
    z-index: 100000;
}
.novideodisplay{
    z-index: 9;
}
</style>

<script>

import { mapGetters } from "vuex";

import {
  SET_HOME_DATA
} from "@/core/services/store/data.module.js";

export default{
   props: ['begin','currentVideo'],
   name: "userInfo",
   data() {
    return {
    }
   },
   created() {
      this.previous = localStorage.getItem('previous');

   },
   mounted(){

   },
   methods:{
       VideoClick(val, videoId) {
            this.$emit('Videoshow', val, videoId)
       },
       backspace() {
            this.$store.commit(SET_HOME_DATA, {});
            this.$router.go(-1);
       }
   },
   computed: {
        ...mapGetters([
            "currentUser",
            "homeData"
        ]),
   }
}
</script>
